import axios from "axios";


const api = axios.create({
  baseURL: 'https://api.track-mfg.mimiq.io',
  timeout: 30000,
  headers: { Accept: "*", "Access-Control-Allow-Origin": "*","Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept" },
  
});

export default api;

