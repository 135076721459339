import { Navigate,useRoutes } from "react-router-dom";
import MatchedList from "./Matched/MatchedList";
import MatchCodes from "./Match/MatchCodes";

export default function Router() {
    const baseRouter = {
      children: [
        {path: "/", element: <Navigate to="/matchcodes" replace /> },
        { path: "/matchcodes", element: <MatchCodes /> },
        { path: "/matchedlist",element: <MatchedList /> },
      ],
    };
  
    const routes = [baseRouter];
  
    return useRoutes(routes);
  }