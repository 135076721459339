
import logo from '../../logo.svg';
import '../../App.css';
import {QrScanner} from '@yudiel/react-qr-scanner';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import api from '../../api'
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from "react-router-dom";

const MatchCodes =()=>{
    const [token,setToken] = useState('')
const [log, setLog] = useState('');
const [buttonLabel, setButtonLabel] = useState('SCAN PCB QR CODE');
const [pcbQrDecoded, setPcbQrDecoded] = useState(false);
const [boxQrDecoded, setBoxQrDecoded] = useState(false);
const [openScanner, setOpenScanner] = useState(false);
const [toastOpen, setToastOpen] = useState(false);
const [toastMessage, setToastMessage] = useState('');
const [pcbQrCodeStr, setPcbQrCodeStr] = useState('');
const [boxQrCodeStr, setBoxQrCodeStr] = useState('');
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const navigate = useNavigate();
useEffect(()=>{
  var localToken = localStorage.getItem("jwt");
  if(localToken && localToken.length>0){
    setToken(localToken)
  }
},[])


const onDecode = (code) => {
  if(!pcbQrDecoded){
    var incTest = code.includes("http")
    var lengTest = code.length>0
    if(!incTest && lengTest){
      setPcbQrCodeStr(code)
      setPcbQrDecoded(true);
      setOpenScanner(false);
      setButtonLabel("SCAN BOX QR CODE");
    }
  }else if(pcbQrDecoded && !boxQrDecoded){
    var incTest = code.includes("http")
    var lengTest = code.length>0
    if(incTest && lengTest){
      var splitted = code.split('qrdevice/')
      var box = splitted[1]
      var boxCodes = box.split('-')
      var boxCode = boxCodes[1]+boxCodes[2]
      setBoxQrCodeStr(boxCode)
      setBoxQrDecoded(true);
      setOpenScanner(false);
      setButtonLabel("MATCH QR CODES");
    }
  }
}

const onError = (err)=>{
  showToast(err?.message);
}

const showToast = (message) => {
  setToastMessage(message)
  setToastOpen(true)
  setTimeout(() => {
    setToastOpen(false)
  }, 5000);
}

const onLogin = ()=>{
  if(email.length>0 && password.length>0){
    api.post(`/login`,{email,password})
    .then((res)=>{
      const token = res.data.token
      localStorage.setItem("jwt",token);
      setToken(token)
      showToast('Login Successfully !')
    }).catch((err)=>{
      var msg = err.response.data.error
      showToast(msg)
    })
  }else {
    showToast("All field required.")
  }
}

const handleClose = () => {
  setToastOpen(false)
}

const onQrScannerOpen = () => {
  
  if(!boxQrDecoded || !pcbQrDecoded){
    setOpenScanner(true);
  }else if(boxQrDecoded && pcbQrDecoded && pcbQrCodeStr.length>0 && boxQrCodeStr.length>0){
      if(token.length>0){
        const postData = {dev_eui:pcbQrCodeStr,qr_code:boxQrCodeStr};
          api.post('/match_qr_code',postData, {headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          }})
          .then((res)=>{
            //device registered success
            console.log("res",res)
            showToast(res.data.message)
            setPcbQrDecoded(false)
            setBoxQrDecoded(false)
            setOpenScanner(false)
            setPcbQrCodeStr('')
            setBoxQrCodeStr('')
            setButtonLabel('SCAN PCB QR CODE')
          }).catch((err)=>{
            //device registered error
            var msg = err.response.data.error
            if(err.response.status === 401){
              localStorage.removeItem("jwt")
              setToken('')
            }
            showToast(msg)
            console.log("err",err)
            // showToast("Device registiration failed !")
          });
      }else {
        showToast("Please relogin.")
        localStorage.removeItem("jwt")
        setToken('')
      }
    }
  }


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} width={250} className="App-logo" alt="logo" />

        {token.length>0 ?(
        <div>
          <Button  variant="contained" onClick={()=>navigate("/matchedlist")}>Matched List</Button>  
        <p style={{color:'black'}}>
          Mimiq TRACK - MFG - Qr Match
        </p>
        <FormGroup>
            <FormControlLabel control={<Checkbox checked={pcbQrDecoded} />} label='PCB QR CODE' disabled={true} sx={{ '& .MuiSvgIcon-root': { fontSize: 48 } }} />
            {pcbQrCodeStr.length>0 ? (
              <div>
                <p style={{color:'black'}}>
                PCB QR : {pcbQrCodeStr}
                </p>
              </div>)  : <div/>}
            {pcbQrDecoded ? (
              <FormControlLabel control={<Checkbox checked={boxQrDecoded} />} label="BOX QR CODE" disabled={true} sx={{ '& .MuiSvgIcon-root': { fontSize: 48 } }} />
            ):<div></div>}
             {boxQrCodeStr.length>0 ? (
              <div>
                <p style={{color:'black'}}>
                BOX QR : {boxQrCodeStr}
                </p>
              </div>)  : <div/>}
        <Button  variant="contained" onClick={()=>onQrScannerOpen()}>{buttonLabel}</Button>      
        </FormGroup>
       
        {openScanner ? (
          <div>
            <p style={{color:'black'}}>
           QR Scan Result : {log}
        </p>  
          <QrScanner 
          scanDelay={500}
            onDecode={(result) => onDecode(result)}
            onError={(error) => onError(error)}
          />
          </div>
        ):<div/>}
       </div>) : <div>
        <p style={{color:'black'}}>
        MFG QR Match - Login
          </p>
          <Grid container spacing={2} >
            <Grid item xs={12}>
          <TextField  id="outlined-basic" value={email} onChange={(val)=>setEmail(val.target.value)} label="Email Address" variant="outlined" />
          </Grid>
          <Grid item xs={12}>
          <TextField  id="outlined-basic" value={password} onChange={(val)=>setPassword(val.target.value)} label="Password" type="password" variant="outlined" />
          </Grid>
          <Grid item xs={12}>
          <Button  variant="contained" onClick={()=>onLogin()}>Login</Button>      
          </Grid>
          </Grid>
       </div> }
       <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        open={toastOpen}
        onClose={handleClose}
        message={toastMessage}
        key={'topright'}
      />
      </header>
    </div>
  );
}

export default MatchCodes;