import logo from '../../logo.svg';
import { useState,useEffect } from 'react';
import '../../App.css';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { DataGrid,GridToolbarQuickFilter } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import api from '../../api'
import { format } from "date-fns";
import Box from '@mui/material/Box';


const MatchedList =()=>{
    const [dataCount, setDataCount] = useState(0);
    const [page, setPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [query, setQuery] = useState('');
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();

    const loadServerRows = ()=>{
      var queryStr = `?limit=10&page=${page+1}`;
      if(query.length>2){
        queryStr += `&search=${query.toUpperCase()}`
      }
      console.log("query",queryStr)
      return new Promise((resolve,reject)=>{
        api.get('/matched_devices'+queryStr, {headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        }}).then((res)=>{
          console.log("ress",res)
          var data = res.data.items;
          data.forEach(x=>{
            x.id = `${x.dev_eui}`
            x.qr_code_match_date= format(
              new Date(x.qr_code_match_date.toString()),
              "dd  MMMM yyyy HH:mm"
            );
          });
          setData(res.data.items)
          setDataCount(res.data.totalItems)
          resolve()
        }).catch((err)=>{
          console.log("error",err.response.data.error)
          if(err.response.status === 401){
            localStorage.removeItem("jwt")
            navigate("/matchcodes")
          }
          showToast(err.response.data.error)
          reject()
        })
      })
    };

    const handleClose = () => {
      setToastOpen(false)
    }

    const showToast = (message) => {
      setToastMessage(message)
      setToastOpen(true)
      setTimeout(() => {
        setToastOpen(false)
      }, 5000);
    }

    useEffect(()=>{
      console.log("burada 1")
        setLoading(true);
        loadServerRows()
        .then(()=>{
          setLoading(false)
        }).catch((err)=>{
          setLoading(false);
          showToast(err)
        });
    },[page,refresh]);

    const columns = [
        { field: 'qr_code', headerName: 'QR CODE', width: 150 },
        { field: 'dev_eui', headerName: 'DEV EUI', width: 200 },
        { field: 'qr_code_match_date', headerName: 'MATCH DATE', width: 200 },
      ];

      const onFilterChange = (obj)=>{
        if(obj.quickFilterValues && obj.quickFilterValues.length>0){
          setQuery(obj.quickFilterValues[0])
        console.log("filterchange",obj.quickFilterValues[0])
        if(obj.quickFilterValues[0].length>2){
          setQuery(obj.quickFilterValues[0])
          setPage(0)
          setRefresh(!refresh)
          console.log("page changed")
        }
      }else {
        setQuery('')
        setPage(0)
        setRefresh(!refresh)
      }
      }

      function QuickSearchToolbar() {
        return (
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <GridToolbarQuickFilter sx={{width:'100%'}}  />
          </Box>
        );
      }


    return(
        <div className="App">
      <header className="App-header">
        <img src={logo} width={250} className="App-logo" alt="logo" />
            <div>
                <Button  variant="contained" onClick={()=>navigate("/matchcodes")}>Match New QR Codes</Button>  
                <p style={{color:'black'}}>
                Matched List ({dataCount})
                </p>
                <DataGrid
                rows={data}
                columns={columns}
                scrollbarSize={300}
                initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
                }}
                paginationMode="server"
                rowCount={dataCount}
                onpage
                onPageChange={(newPage) => {
                  setPage(newPage)
                  console.log("new page",newPage)
                }}
                onPaginationModelChange={(newPage)=>{
                  console.log("modelchange",newPage.page)
                  setPage(newPage.page)
                }}
                loading={loading}
                slots={{ toolbar: GridToolbarQuickFilter }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true }
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                onFilterModelChange={onFilterChange}
            />
        </div>
      </header>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        open={toastOpen}
        onClose={handleClose}
        message={toastMessage}
        key={'topright'}
      />
    </div>
    )
}

export default MatchedList;